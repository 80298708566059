import {Icon, Input,message } from 'antd';
import React from 'react';
// import companyService from '../../../services/companyService'
// const { Search } = Input;

class SearchBar extends React.Component {
    constructor(props) {
        super()
        this.state = {
            searchString: [],
            input: ''
        }
    }
    onSearch = (value) => {
        console.log('SearchValue', value)
        // let values = value.split(' ')
        let data = { title: value}
        this.props.searchFunction(data)
    }
    handleChange = (e) => {
        // console.log('search ',e.target.value)
        this.setState({
            input: e.target.value
        })
    }


    handleSubmit = () => {
        if(this.state.input.trim() !== ""){
            let data = { title: this.state.input.trim()}
             this.props.searchFunction(data)
            }
            else{
                message.info("Please enter the value")
            }
       
    }
    render() {
        // const { defaultValue, placeholder } = this.props
        return ( 
            <Input onChange={this.handleChange} required placeholder={this.props.placeholder} className="w-100" suffix={( <Icon type="search" className="search-icon" onClick={this.handleSubmit}/>
                // <Button id="home_searchbar" onClick={this.handleSubmit} className="search-btn" size="large" type="primary">
                    
                // </Button>
            )}
            ></Input>
       
        )

    }
}

export default SearchBar