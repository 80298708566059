const tilesInitialState = []


const tilesReducer = (state=tilesInitialState, action) => {
  switch(action.type){
    case 'GET_TILES_DATA':
      return [...action.payload]
    default:
      return state
  }
}

export default tilesReducer