import React,{ Suspense, lazy } from 'react';
// import { BrowserView, MobileView, isBrowser, isMobile,CustomView } from 'react-device-detect';
import { Spin} from 'antd';
import { Route, Redirect ,Switch} from 'react-router-dom';
import loadable from 'react-loadable';
import LoadingComponent from 'components/Loading';
import NotFoundPage from './error404/NotFoundPage';
// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
// custom
import "styles/layout.scss"
import "styles/theme.scss"
import "styles/ui.scss"
import "styles/vendors.scss"
import "styles/ui/wob/style.css"
// const HeaderMobile = React.lazy(() => import("../routes/header/components/HeaderMobile"));
// const FooterMobile = React.lazy(() => import("../routes/footer/components/FooterMobile"));
let AsyncHomePage = loadable({
  loader: () => import('routes/home/'),
  loading: LoadingComponent
})
let AsyncWebinarPage = lazy(()=>import('routes/webinars'))
let AsyncPrivacyPolicies = lazy(()=>import('routes/privacypolicy/'))
let AsyncTermsConditions = lazy(()=>import('routes/termscondition/'))
let AsyncAboutUs = lazy(()=>import('routes/aboutus/'))
let AsyncContactUs = lazy(()=>import('routes/contactus/'))
let AsyncCareers = lazy(()=>import('routes/careers/'))

let AsyncFeatureMobile = lazy(()=>import('routes/MobilePages/FeaturedMobile'))
let AsyncSolutionMobile = lazy(()=>import('routes/MobilePages/SolutionMobile'))

let AsyncSearchMobilePage = loadable({
  loader: () => import('../routes/MobilePages/SearchMobile'),
  loading: LoadingComponent
})
let AsyncWOBSearchMobile = loadable({
  loader: () => import('../routes/MobilePages/WobSearchMobile'),
  loading: LoadingComponent
})
let AsyncWOBSearch = loadable({
  loader: () => import('../routes/WobSearch'),
  loading: LoadingComponent
})
let AsyncWebinarLivePage = loadable({
  loader: () => import('routes/webinars/components/webinarLive'),
  loading: LoadingComponent
})
let AsyncJobProfilePage = loadable({
  loader: () => import('routes/jobs'),
  loading: LoadingComponent
})
let AsyncSearchPage = loadable({
  loader: () => import('routes/search/'),
  loading: LoadingComponent
})

let AsyncTeams = loadable({
  loader: () => import('routes/team/'),
  loading: LoadingComponent
})
let AsyncException = loadable({
  loader: () => import('routes/exception/'),
  loading: LoadingComponent
})
let Akwire = loadable({
  loader: () => import('routes/akwire/'),
  loading: LoadingComponent
})
let CompanyProfile = loadable({
  loader: () => import('routes/company/'),
  loading: LoadingComponent
})
let FAQPages = loadable({
  loader: () => import('routes/faq/'),
  loading: LoadingComponent
})
let Wob = loadable({
  loader: () => import('routes/wob/'),
  loading: LoadingComponent
})
let AsyncJobMobileProfilePage = loadable({
  loader: () => import('routes/jobsMobile'),
  loading: LoadingComponent
})

let Faqjobseeker = loadable({
  loader: () => import('../../src/routes/faq/routes/jobseeker'),
  loading: LoadingComponent
})
let Faqpartner = loadable({
  loader: () => import('../../src/routes/faq/routes/partner'),
  loading: LoadingComponent
})
let Faqcompany = loadable({
  loader: () => import('../../src/routes/faq/routes/company'),
  loading: LoadingComponent
})
let Faqgeneral = loadable({
  loader: () => import('../../src/routes/faq/routes/general'),
  loading: LoadingComponent
})

let AsyncmailerPage = loadable({
  loader: () => import('../../src/routes/tdc/tdcmail'),
  loading: LoadingComponent
})

class App extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    const { match, location } = this.props;
    // let path = this.props.match.path
    const isRoot = location.pathname === '/' ? true : false;
    if (isRoot) {
      return (<Redirect to={'/home'} />);
    }
    //console.log(window.location.href.includes(webinars));
    return (
      <div id="app" className="Mobileview">
        
         <Suspense fallback={<Spin/>}>
        <Switch>
        <Route path={`${match.url}FeatureMobile`} exact component={AsyncFeatureMobile} />
        <Route path={`${match.url}SolutionMobile`} exact component={AsyncSolutionMobile} />
        <Route path={`${match.url}company`} exact component={AsyncHomePage} />
        <Route path={`${match.url}company/:id`} exact component={CompanyProfile} />
        <Route path={`${match.url}search`} exact component={AsyncSearchPage} />
        <Route path={`${match.url}search/:id`} exact component={AsyncSearchPage} />
        <Route path={`${match.url}jobs/:id`} exact component={AsyncJobProfilePage} />
        <Route path={`${match.url}search/jobs/:id`} exact component={AsyncJobProfilePage} />
        <Route path={`${match.url}jobsMobile/:id`} exact component={AsyncJobMobileProfilePage} />
        <Route path={`${match.url}search/jobsMobile/:id`} exact component={AsyncJobMobileProfilePage} />
        {/* <Route path={`${match.url}searchcompany/:id/:type`} exact component={AsyncSearchPage} /> */}
        <Route path={`${match.url}webinars`} exact component={AsyncWebinarPage} />
        <Route path={`${match.url}webinars/:id`} exact component={AsyncWebinarLivePage} />
        <Route path={`${match.url}aboutus`} exact component={AsyncAboutUs} />
        <Route path={`${match.url}faq`} exact component={FAQPages} />
        <Route path={`${match.url}contactus`} exact component={AsyncContactUs} />
        <Route path={`${match.url}careers`} exact component={AsyncCareers} />
        <Route path={`${match.url}team`} exact component={AsyncTeams} />
        <Route path={`${match.url}termscondition`} exact component={AsyncTermsConditions} />
        <Route path={`${match.url}privacypolicy`} exact component={AsyncPrivacyPolicies} />
        <Route path={`${match.url}exception`} exact component={AsyncException} />
        <Route path={`${match.url}home`} exact component={AsyncHomePage} />
        <Route  path={`${match.url}akwire`} exact component={Akwire} />
        <Route  path={`${match.url}solution`} exact component={AsyncHomePage} />
        <Route  path={`${match.url}featured`} exact component={AsyncHomePage} />
        {/* <Route path={`${match.url}faq/company`} component={CompanyFAQs} /> */}
        <Route exact path={`${match.url}wob`}  component={Wob} />
        {/*Added routes for Home to support Campaigns */}
        <Route path={`${match.url}home/magazine`} exact component={AsyncHomePage} />
        <Route path={`${match.url}home/myntra`} exact component={AsyncHomePage} />
        <Route path={`${match.url}home/campaign`} exact component={AsyncHomePage} />
        <Route path={`${match.url}home/campaignmail/:emailId`} exact component={AsyncmailerPage} />
        <Route path={`${match.url}faq/jobseeker`} exact component={Faqjobseeker} />
        <Route path={`${match.url}faq/partner`} exact component={Faqpartner} />
        <Route path={`${match.url}faq/company`} exact component={Faqcompany} />
        <Route path={`${match.url}faq/general`} exact component={Faqgeneral} />
        <Route path={`${match.url}Mobilesearch`} exact component={AsyncSearchMobilePage} />
        <Route path={`${match.url}Mobilesearch/:id`} exact component={AsyncSearchMobilePage} />
        <Route path={`${match.url}WOBsearch`} exact component={AsyncWOBSearch} />
        <Route path={`${match.url}WOBsearch/:id`} exact component={AsyncWOBSearch} />
        <Route path={`${match.url}WobMobilesearch`} exact component={AsyncWOBSearchMobile} />
        <Route path={`${match.url}WobMobilesearch/:id`} exact component={AsyncWOBSearchMobile} />
        {/*End*/}
        <Route component={NotFoundPage} />
        </Switch>
        </Suspense>
      </div>
    );
  }
}

export default App;
