const PAGEROUTES = {
    home: '#/mentee/dashboard',
    mentor: "http://mentor.stage.shenzyn.com/"
}

// dashboard links
PAGEROUTES.dashboardComponentLinks = {
    jobsapplied: '#/app/jobs/saved-jobs/applied-jobs',
    savedjobs: '#/app/jobs/saved-jobs',
    recommendedjobs: '#/app/jobs/recommended-jobs',
    profileviews: '#/app/profile/profile-views' // redirect profile page
}

PAGEROUTES.JsLinks = {
    jobsUrlLink: ''
}

// footer links
PAGEROUTES.footerLinks = {
    About: 'https://www.shenzyn.com/#/aboutus',
    Career: 'https://www.shenzyn.com/#/career',
    Help: 'https://www.shenzyn.com/#/contact',
    Privacy: 'https://www.shenzyn.com/#/privacypolicy',
    Terms: 'https://www.shenzyn.com/#/termsandconditions',
    Facebook: 'https://www.facebook.com/beyondiversity',
    Instagram: 'https://www.instagram.com/beyondiversity/',
    LinkedIn: 'https://www.linkedin.com/company/bd-foundation/?viewAsMember=true',
    // YouTube: 'https://www.youtube.com/channel/UCJ3hSpePirYqXCAJo75OIhQ',
    Twitter: 'https://twitter.com/beyondiversity'
}

// header links
PAGEROUTES.JobDetailComponentLinks = {
    jobsdescriptions: '#/app/jobs/job-details',
}
PAGEROUTES.endPointUrl = {
    default: process.env.REACT_APP_API_URL,
    // stage: 'https://stage-api.shenzyn.com/',
    // default: 'https://api.shenzyn.com/',
    // default: "http://localhost:8080/", //testing local url
    local: 'http://localhost:8080/',
}
PAGEROUTES.UIUrl = {
    default: process.env.REACT_APP_FE_URL,
    // default:"http://localhost:3000/", //testing local url
    local: 'http://localhost:3000/',
    stage: 'http://beyondiversity.stage.shenzyn.com/',
    prod: 'https://beyondiversity.shenzyn.com/'
}

PAGEROUTES.JSUrl = {
    default: process.env.REACT_APP_EMP_URL
}
PAGEROUTES.EmployerUrl = {
    default: process.env.REACT_APP_EMP_URL
}
PAGEROUTES.PartnerUrl = {
    default: process.env.REACT_APP_PARTNER_URL
}
PAGEROUTES.MentorUrl = {
    default: process.env.REACT_APP_MENTOR_URL
}

PAGEROUTES.TDCcampigndetails = {
    campaignName : "TDC",
    partnerCompanyName : "Shenzyn",
    partnerEmailId : "wehearyou@shenzyn.com",
    couponCode : "28876"
}

export default PAGEROUTES;