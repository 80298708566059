import { combineReducers } from 'redux';
import settingsReducer from './settingsReducer';
import tilesReducer from './tilesReducer'
import { routerReducer } from 'react-router-redux';

const rootReducer = combineReducers({
  settings: settingsReducer,
  tiles: tilesReducer,
  routing: routerReducer,

});

export default rootReducer;
