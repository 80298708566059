import React from 'react';
import { Input, message} from 'antd';
// import companyService from '../../../services/companyService'

const { Search } = Input;

class SearchBar extends React.Component {
    constructor(props) {
        super()
        this.state = {
            // searchString: [],
            
        }
    }
    
    onSearch = (value) => {
        if(value.trim() !== ""){
        let values = value.split(/[ ,]+/)
        let data = { searchString: values, location: [""] }
        this.props.searchFunction(data)
        }
        else{
            message.info("Please enter the value")
        }
    }
    render() {
        const { placeholder } = this.props
        
        return (
        
            
                <Search
                className="job-search-button"
                placeholder={placeholder}
                // allowClear
                // enterButton
                onSearch={this.onSearch}
                
            />
            
            
        
            
        )
    }
}

export default SearchBar