import ServiceBase from '../../services/serviceBase'
import api from '../../constants/pageRoutes'

export const getTiles = (tiles) => {
  return { type: 'GET_TILES_DATA', payload: tiles }
}

export const startGetTiles = () => {
  return ((dispatch) => {
    ServiceBase.get(api.endPointUrl.default + 'tiles/customtiledata')
      .then((response) => {
        // console.log('Response', response)
        let data = response.data
        dispatch(getTiles(data))
      })
      .catch((err) => {
        console.log("Error in catch", err)
      })
  })
}

