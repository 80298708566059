import React from 'react';
import {Menu, Dropdown } from 'antd';
// import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import * as ReactBootStrap from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import Searchbar from './SearchBar';
import WebinarSearchbar from '../../webinars/components/WebinarSearchBar';
import JobSearchbar from './../../search/components/SearchBar'
import WOBSearchbar from './../../WobSearch/components/SearchBar'
import {withRouter,NavLink} from 'react-router-dom'
//import {BsChevronCompactDown} from "react-icons/bs";
import { HashLink as Link } from 'react-router-hash-link';
// import JobSearchBar from './../../search/components/SearchBar'
const { SubMenu } = Menu;
class Header extends React.Component {
    constructor(props) {
        super()
    }
    getJobs  =(data) => {
        this.props.searchFunction(data)
    }
    searchWebinars = (data) => {
        console.log(this.props);
        this.props.searchWebinarFunction(data)
      }
      WOBsearchFunction = (data) => {
        console.log(this.props);
        this.props.WOBsearchFunction(data)
      }
    
    render() {
        
        let path = this.props.match.path
        const menu = (
            <Menu className="login-dropdown">
              <Menu.ItemGroup>
                <Menu.Item><a id="home_login_Jobseeker" href={process.env.REACT_APP_JS_URL} rel="noopener noreferrer" target="_blank">Job Seeker</a></Menu.Item>
                <Menu.Item><a id="home_login_Employer" href={process.env.REACT_APP_EMP_URL} rel="noopener noreferrer" target="_blank">Employer</a></Menu.Item>
                {/* <Menu.Item><a id="home_login_Mentor" href={process.env.REACT_APP_MENTOR_URL} target="_blank">Mentor</a></Menu.Item> */}
              </Menu.ItemGroup>
              <SubMenu title="Partner" style={{marginLeft:'10px'}} className="login-dropdown-sub">
                <Menu.Item><a id="home_login_hiring_partner" href={process.env.REACT_APP_PARTNER_URL} rel="noopener noreferrer" target="_blank" >Hiring Partner</a></Menu.Item>
                <Menu.Item><a id="home_login_training_partner" href="https://admin.equip-app.com/#/tenantLogin" rel="noopener noreferrer" target="_blank">Training Partner</a></Menu.Item>
              </SubMenu>
              {/* <Menu.ItemGroup>
                <Menu.Item><a id="home_login_Mentor" href={process.env.REACT_APP_MENTOR_URL} rel="noopener noreferrer" target="_blank">Mentor</a></Menu.Item>
              </Menu.ItemGroup> */}
            </Menu>
           
          );
        return (
            <ReactBootStrap.Navbar expand="md" className="bg-header fixed-top custom-toggler mb-0" >
                <div className="container-fluid">
                    <ReactBootStrap.Navbar.Brand href="/">
                        <img className="container-fluid-logo" src='https://shenzyn-uploads.s3.ap-south-1.amazonaws.com/Assets/Shenzyn-TM.svg' alt="Shenzyn Logo " />
                    </ReactBootStrap.Navbar.Brand>
                    <div className="container-fluid-search">
                    {
                        (path.split('/')[1]===`search` )?<JobSearchbar placeholder="Where do you aspire to work at ?" searchFunction={this.getJobs}/>:
                        (path.split('/')[1]===`webinars` )?<WebinarSearchbar searchFunction={this.searchWebinars} placeholder={"Search Webinars"} />:
                        (path.split('/')[1]===`WOBsearch` )?<WOBSearchbar searchFunction={this.WOBsearchFunction} placeholder={"Where do you aspire to work at ?"} />:
                        <Searchbar />
                    }
                    </div>
                    <ReactBootStrap.Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <div style={{height:"72px"}}>
                    </div>
                    <ReactBootStrap.Navbar.Collapse id="basic-navbar-nav" className="ml-2">
                        <ReactBootStrap.Nav className="ml-auto">
                            {/* <ReactBootStrap.Nav.Item className="mt-3">
                                <NavLink to="/home" activeClassName="active">
                                    Home
                                </NavLink>
                            </ReactBootStrap.Nav.Item> */}
                            <ReactBootStrap.Nav.Item className="mt-3">
                                <NavLink to={`/search/CS~`} className={(path.split('/')[1]===`search`||path.split('/')[1]===`company`||path.split('/')[1]===`jobs`)?"active":''}>
                                Jobs
                                    </NavLink>
                            </ReactBootStrap.Nav.Item>
                            <ReactBootStrap.Nav.Item className="mt-3">
                            <Link to="/solution#landing_solution" smooth className={`${this.props.location.pathname}${this.props.location.hash}` === "/solution#landing_solution"? "active" : ""}>Solutions
                            </Link>
                            </ReactBootStrap.Nav.Item>
                            {/* <ReactBootStrap.Nav.Item className="mt-3">
                                <Link to="/featured#landing_featured" smooth className={`${this.props.location.pathname}${this.props.location.hash}` === "/featured#landing_featured"? "active" : ""}>
                                Featured
                                </Link>
                            </ReactBootStrap.Nav.Item> */}
                            <ReactBootStrap.Nav.Item className="mt-3">
                                <NavLink to="/webinars" id="home_webinar_button"  activeClassName="active">Webinars</NavLink>
                            </ReactBootStrap.Nav.Item>
                              <ReactBootStrap.Nav.Item className="mt-3">
                              <a id="home_login_Mentor" href={process.env.REACT_APP_MENTOR_URL} rel="noopener noreferrer" target="_blank">Mentor</a>
                            </ReactBootStrap.Nav.Item>
                            <ReactBootStrap.Nav.Item className="mt-3" >
                                {/* <ReactBootStrap.NavDropdown title="Login" id="collasible-nav-dropdown">
                                    <ReactBootStrap.NavDropdown.Item id="home_login_Jobseeker" href={process.env.REACT_APP_JS_URL} target="_blank">Jobseeker</ReactBootStrap.NavDropdown.Item>
                                    <ReactBootStrap.NavDropdown.Item id="home_login_Employer" href={process.env.REACT_APP_EMP_URL} target="_blank">Employer</ReactBootStrap.NavDropdown.Item>
                                    <ReactBootStrap.NavDropdown.Item id="home_login_Partner" href={process.env.REACT_APP_PARTNER_URL} target="_blank">Partner</ReactBootStrap.NavDropdown.Item>
                                    <ReactBootStrap.NavDropdown.Item id="home_login_Mentor" href={process.env.REACT_APP_MENTOR_URL} target="_blank">Mentor</ReactBootStrap.NavDropdown.Item>
                                </ReactBootStrap.NavDropdown> */}
                              
                                <Dropdown overlay={menu} >
                                    <p className="ant-dropdown-link header-nav" onClick={e => e.preventDefault()}>
                                    Login
                                    </p>
                                </Dropdown>
                                
                              
                            </ReactBootStrap.Nav.Item>
                        </ReactBootStrap.Nav>
                    </ReactBootStrap.Navbar.Collapse>

                </div>
            </ReactBootStrap.Navbar>
        )
    }
}
export default withRouter(Header)