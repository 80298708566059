import {Icon, Input,message } from 'antd';
import React from 'react';
import {withRouter} from 'react-router-dom'
import { isBrowser, isMobile } from 'react-device-detect';
// const { Search } = Input;


class SearchBar extends React.Component {
    constructor(props) {
        super()
        this.state = {
            input: ''
        }
    }

    handleChange = (e) => {
        // console.log('search ',e.target.value)
        this.setState({
            input: e.target.value
        })
    }


    handleSubmit = () => {
        if(this.state.input.trim() !==''){
            if(isBrowser){
                this.props.history.push(`/search/${this.state.input.trim()}`)
            }
            else if(isMobile && navigator.userAgent.includes("iPad")){
                this.props.history.push(`/search/${this.state.input.trim()}`)
            }
            else
            {
                this.props.history.push(`/Mobilesearch/${this.state.input.trim()}`)                
            }
            
        }
        else{
            message.info("Please enter the value")
        }
    }

     keyUpHandler = (event) =>{
        if(event.key==='Enter'){
            this.handleSubmit()
        }
        
    }
   
    render() {
        // console.log(this.state.input)
        return (
            <Input onChange={this.handleChange} onKeyUp={this.keyUpHandler} required placeholder="Where do you aspire to work at ?" className="w-100" suffix={( <Icon type="search" className="search-icon" onClick={this.handleSubmit} />
                // <Button id="home_searchbar" onClick={this.handleSubmit} className="search-btn" size="large" type="primary">
                    
                // </Button>
            )}
            ></Input>
       
        )
    }
}

export default withRouter(SearchBar)

