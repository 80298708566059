import React from 'react';
// import { Input, Button, Icon } from 'antd';
import { Link } from 'react-router-dom'
import { FaLinkedin, FaYoutube, FaTwitter, FaFacebookSquare, FaInstagram } from 'react-icons/fa';

class Footer extends React.Component {
    // constructor(props) {
    //     super()
    //     this.state = {
    //         input: ''
    //     }
    // }

    render() {
        return (
            <section className="footer-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-3 footer-box ">
                            <img style={{ height: '35px' }} src='https://shenzyn-uploads.s3.ap-south-1.amazonaws.com/Assets/Shenzyn-TM.svg' alt="Shenzyn Logo " />
                            <p className="m-0">
                                <a id="whereareyou_shenzyn" className="footer-link" href="mailto:wehearyou@shenzyn.com" >wehearyou@shenzyn.com</a>
                            </p>
                        </div>
                        <div className="col-sm-7 footer-box">
                            <div className="row footer-links">
                                <div className="col-sm-4">
                                    <li><Link id="AboutUs" to="/aboutus" className="footer-links-a">About Us</Link></li>
                                    <li><Link id="ContactUs" to="/contactus" className="footer-links-a">Contact Us</Link></li>
                                </div>
                                <div className="col-sm-4">
                                    <li><Link id="PrivacyPolicy" to="/privacypolicy" className="footer-links-a">Privacy Policy</Link></li>
                                    <li><Link id="Terms_Conditions" to="/termscondition" className="footer-links-a">Terms &amp; Conditions</Link></li>
                                </div>
                                <div className="col-sm-4">
                                    <li><Link id="search_Careers" to="/search/CS~Shenzyn" className="footer-links-a">Careers</Link></li>
                                    <li><a id="Blog" href="https://blog.shenzyn.com//" target="_blank" rel="noopener noreferrer" className="footer-links-a">Blogs</a></li>
                                    {/* <li><Link to="/team">Team</Link></li> */}
                                </div>
                                {/* <div className="col-sm-3">
                                    <li><a href="https://blog.shenzyn.com//" target="_blank" rel="noopener noreferrer">Blogs</a></li>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-sm-2 footer-box">
                            <p className="m-0 p-0">You can find us at</p>
                            <ul className="footer-social-list">
                                <li><a id="instagram" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/we_shenzyn/" className="footer-social-icons"><FaInstagram style={{ color: '#B01D86' }} /></a></li>
                                <li><a id="linked_in" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/28714426/admin/" className="footer-social-icons"><FaLinkedin style={{ color: '#2F78B7' }} /></a></li>
                                <li><a id="youtube" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCJ3hSpePirYqXCAJo75OIhQ" className="footer-social-icons"><FaYoutube style={{ color: '#F0210F' }} /></a></li>
                                <li><a id="twitter" target="_blank" rel="noopener noreferrer" href="https://twitter.com/Shenzyn_EW" className="footer-social-icons"><FaTwitter style={{ color: '#2F78B7' }} /></a></li>
                                <li><a id="facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Shenzyn-108397103967073/" className="footer-social-icons"><FaFacebookSquare style={{ color: '#475993' }} /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Footer;