import React from 'react';
// import { NavLink } from "react-router-dom";
import Header from '../../routes/header/components/Header'
import Footer from '../../routes/footer/components/Footer'
import Error from './error'

const NotFoundPage = () => {
  return (
    <>
    <Header/>
    <Error/>
      <Footer/>


  </>
  )
}

export default NotFoundPage;
